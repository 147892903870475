@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$payment-banner-background-color: $booking-cta-background-color !default;

.dynamic-blocks-renderer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__item {
		width: 100%;

		.payment-description {
			&__content {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgba($payment-banner-background-color, 0.15);
				background: color-mix(in srgb, $payment-banner-background-color 15%, white);
				border-radius: $border-radius-big;
				flex-direction: column;
				word-wrap: break-word;
				padding: 8px 15px;

				@include gridle_state(md) {
					flex-direction: unset;
					padding: 12px 24px;
				}

				.payment-description__terms {
					display: flex;
					align-items: center;
					margin-left: 5px;

					&-item {
						color: white;
						background: $payment-banner-background-color;
						margin: 0 8px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 2px;
						padding: 1px 8px;
					}
				}

				svg {
					display: flex;
				}

				.floating-button {
					margin-left: 4px;
				}
			}
		}
	}
}
