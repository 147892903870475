@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$product-footer-border: 1px solid $grey-medium !default;
$picture_desktop_width: 241px !default;
$picture_desktop_height: 230px !default;
$picture_mobile_width: 100% !default;
$picture_mobile_height: 250px !default;

.current-merch-collection-item {
	cursor: pointer;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	border-radius: $border-radius-big;

	&__picture {
		position: relative;
		width: $picture_mobile_width;
		height: $picture_mobile_height;

		.progressive-img {
			display: none;

			img {
				border-radius: $border-radius-big $border-radius-big 0 0;
			}
		}

		.background-progressive-image {
			display: block;
			border-radius: $border-radius-big $border-radius-big 0 0;

			&__image {
				border-radius: $border-radius-big $border-radius-big 0 0;
			}
		}

		@include gridle_state(md) {
			width: $picture_desktop_width;
			height: $picture_desktop_height;

			.progressive-img {
				display: block;
			}

			.background-progressive-image {
				display: none;
			}
		}
	}

	&__stamp {
		position: absolute;
		bottom: 0;
		right: 0;

		.decreasing-price-stamp {
			margin-bottom: -1px; // supprime un petit espace qui peut apparaitre en fonction du  niveau de zoom
		}
	}

	&__footer {
		padding: 10px 10px;
		height: 92px;
		width: $picture_mobile_width;
		border: none;
		background: white;
		box-sizing: border-box;
		border-radius: 0 0 $border-radius-big $border-radius-big;
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 5px;
		align-items: center;

		&-right {
			display: flex;
			align-items: center;

			.relative-link {
				width: 50px;
				height: 50px;
			}
		}

		@include gridle_state(md) {
			padding: 10px 15px;
			width: $picture_desktop_width;
		}
	}

	&__label {
		@extend %font-bold;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 1.6rem;
		line-height: 1.2;

		// We ensure that the style is not overwritten by the heading style (font-size) specify in sanity
		> * {
			@extend %font-bold;
			font-size: 1.6rem;
			line-height: 1.2;
		}
	}

	&__intro {
		margin-top: 6px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 1.4rem;
		line-height: 1.2;

		// We ensure that the style is not overwritten by the heading style (font-size) specify in sanity
		> * {
			@extend %font-regular;
			font-size: 1.4rem;
			line-height: 1.2;
		}
	}
}
