@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.merch-product-list-block {
	&__title {
		margin-bottom: 20px;
	}

	.product-list {
		padding-left: 0;
		padding-right: 0;
	}
}
