/***************************
 * = COULEURS
 ***************************/

$color-primary: #2800a0;
$color-primary-dark: #23057d;
$color-primary-light: #370abd;
$color-secondary: #00d66c;
$color-secondary-dark: #05af5b;
$color-secondary-light: #17f286;
$color-tertiary: #ff3268;
$color-tertiary-dark: #e02153;
$color-tertiary-light: #ff5d88;
$black: #000000;
$grey-dark: #606060;
$grey-light: #f7f7f7;
$grey-medium: #cccccc;
$color-error: #a94442;
$color-valid: #0abf74;
$color-info: #3493dc;

/***************************
 * = FONTS
 ***************************/

%beautify {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%font-regular {
	@extend %beautify;
	font-family: "nexa", sans-serif;
}

%font-bold {
	@extend %beautify;
	font-family: "nexa-bold", sans-serif;
}

// Gros titre (nombre de vente sur listing) et Votre Hotel sur la page listing
%h1 {
	@extend %font-bold;
	font-size: 2.4rem;
	line-height: 24px;

	@include gridle_state(md) {
		font-size: 4rem;
		line-height: 48px;
	}
}

// Chambres, restaurants, lifestyle, Bon savoir sur la fiche produit
%h2 {
	@extend %font-bold;
	font-size: 2.2rem;
	line-height: 24px;

	@include gridle_state(md) {
		font-size: 2.5rem;
		line-height: 40px;
	}
}

// nom des chambres et restaurants (titre des photos)
%h3 {
	@extend %font-bold;
	font-size: 1.8rem;

	@include gridle_state(md) {
		font-size: 2rem;
		line-height: 26px;
	}
}

// details de cette offre, prix de la quote et paiement
// label des radio button
// titre des label du moteur de recherche
%h4 {
	@extend %font-bold;
	font-size: 1.6rem;
}

%subheading {
	@extend %font-bold;
	font-size: 1.5rem;
	line-height: 17px;

	@include gridle_state(md) {
		font-size: 3rem;
		line-height: 36px;
	}
}

%text {
	@extend %font-regular;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-secondary {
	@extend %font-regular;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%text-secondary-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%headline {
	@extend %font-bold;
	font-size: 17px;
	line-height: 1.23;
	color: white;
}

%link {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $color-tertiary;
	cursor: pointer;
	text-decoration: none;

	@include gridle_state(md) {
		&:hover {
			text-decoration: underline;
		}
	}
}

/***************************
 * = COMMUN
 ***************************/

$border-radius-small: 5px;
$border-radius-big: 10px;
$border-radius-top: 0 0 0 0;
$border-radius-bottom: 0 0 $border-radius-big $border-radius-big;
$amount-color: $color-secondary;
$header-background-color: white;
$header-border-bottom: 1px solid $grey-medium;
$header-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
$header-menu-active-color: $color-secondary;
$header-text-color: $color-secondary;
$header-text-transform: none;
$account-aside-header-item-color: $color-secondary;

$menu-account-menu-item-color: $color-secondary;
$menu-account-submenu-item-color: $black;

$auth-header-background-color: white;
$auth-header-mobile-background-color: $auth-header-background-color;
$auth-header-text-color: $black;
$auth-header-mobile-text-color: $auth-header-text-color;

$cookie-policy-footer-background: $black;
$cookie-policy-footer-color: white;
$cookie-policy-footer-text-transform: uppercase;
$phone-number-color: $color-tertiary;

$hamburger-layer-color: $color-secondary;
$common-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

$badge-background-color: $color-primary;

$payment-banner-background-color: $color-secondary;
$background-primary-gradient: linear-gradient(90.61deg, #00ad57 0.53%, #40e091 99.47%);

/***************************
 * = FORM
 ***************************/

$active-color: $color-primary;
$active-color-dark: $color-primary-dark;
$active-color-light: $color-primary-light;
$input-border-color: $grey-medium;
$input-border: 2px solid $active-color;
$input-border-radius: $border-radius-small;
$radio-checked-background: $grey-light;
$radio-hover-border-color: $active-color-light;
$radio-outline: none;
$radio-outline-error: none;
$input-shadow-box: none;
$button-primary-background-color: $color-tertiary;
$button-primary-background-color-dark: $color-tertiary-dark;
$button-box-shadow: inset 0 -0.1875rem 0.0625rem #dd2f5d;
$button-border-radius: 50px;

%master-button {
	text-transform: none;
	border-radius: $button-border-radius;
}

%brand-button--secondary {
	border-radius: 8px;
	border: 1px solid $grey-medium;
	box-shadow: 0px -3px 2px 0px $grey-medium inset;
}

/***************************
 * = FICHE PRODUIT
 ***************************/
$product-accommodation-text-transform: uppercase;

$deal-background-color: rgba(0, 214, 108, 0.9);
$product-deal-text-color: white;
$product-deal-background-color: rgba(0, 214, 108, 0.9);

$offer-mobile-background-color: $color-primary;

$bloc-contact-body-background: $grey-dark;
$bloc-contact-text-color: white;

$product-aside-box-shadow: $common-box-shadow;

$datepicker-background-color: $color-secondary;

/***************************
 * = MOTEUR DE RECHERCHE
 ***************************/

$search-engine-offer-color: $black;

/***************************
 * = SMARTDP
 ***************************/

$smartdp-amount-color: $color-secondary;
$smartdp-flash-sale-color: $color-secondary;
$smartdp-svg-map-color: $color-tertiary;
$smartdp-search-marketing-color: $color-primary;
$smartdp-horizontal-filter-button-border-radius: 50px;
$smartdp-map-marker-border-radius: 50px;
$smartdp-listing-button-background-color: $color-primary;
$menu-account-incentive-message-background-color: #eedac8;
$menu-account-incentive-message-text-color: $black;
$account-aside-menu-item-color: $color-secondary;
$sdp-listing-actionbar-background: #eedac8;
$menu-account-submenu-item-color: black;

/***************************
 * = TUNNEL DE REZA | COMMUN
 ***************************/
$booking-bloc-title-main-background-color: $color-secondary;
$booking-amount-upgrade-price-color: $black;
$booking-item-margin: 15px;
$booking-cta-box-shadow: inset 0 -0.1875rem 0.0625rem darken($color-valid, 10%);

$quotation-price-detail-toggle-button-background: $color-primary;
$quotation-price-detail-total-background: $color-primary;
$quotation-price-detail-total-border: 1px solid $color-primary;
$quotation-price-detail-total-font-color: white;
$quotation-price-detail-pay-now-border: 1px solid white;
$quotation-price-detail-text-color: white;
$quotation-price-detail-total-margin-bottom: 10px;

/***************************
 * = TUNNEL DE REZA | QUOTE
 ***************************/
$booking-badge-background-color: $color-primary;
$badge-font-color: white;
$badge-border: none;
$badge-border-radius: 40px;

$quotation-filter-sort-background: white;

$flight-filter-button-background-color: $grey-light;
$flight-filter-button-color: $black;
$flight-filter-button-border-radius: $border-radius-big;
$flight-filter-background-color: $active-color;

$quotation-luggage-included-border-radius: $border-radius-small;
$quotation-luggage-included-outline: unset;

$quotation-cabins-margin: 0;
$quotation-cabins-filter-header-background: $grey-light;
$quotation-cabins-filter-header-border: none;
$quotation-cabins-filter-border-bottom: 2px solid $flight-filter-background-color;

/***************************
 * = TUNNEL DE REZA | PAYMENT
 ***************************/
$insurances-radio-first-color: #2800a0;
$insurances-radio-second-color: #606060;
$payment-insurances-name-color: $insurances-radio-first-color;

/***************************
 * = TUNNEL DE REZA | CONFIRMATION
 ***************************/
$booking-confirmation-reference-color: $color-tertiary;

/***************************
 * = SMARTDP
 ***************************/
$smartdp-search-form-background-color: $color-primary;

/***************************
 * = LISTING
 ***************************/
$listing-background-color: $grey-light;
$product-cta-background-color: $button-primary-background-color;
$product-cta-border: 1px solid $button-primary-background-color;
$product-cta-button-radius: 50%;
$product-footer-border: none;
$product-deal-public-price-line-through-color: $black;
$product-name-text-transform: none;
$product-intemporelle-color: $color-primary;
$listing-actionbar-title-display-on-mobile: block;
$listings-product-background-color: $grey-light;
$listing-header-background-color: $grey-light;
$colored-product-badge-background-color: $color-primary;
$colored-product-badge-text-transform: uppercase;
$colored-product-badge-border-radius: 5px;
$merchandising-simple-width: 100%;
$listing-time-border-radius: 50px;
$listing-time-background-color: $color-primary;

/***************************
 * = MERCHANDISING
 ***************************/
$merchandising-listing-background-color: $grey-light;

/***************************
 * = AUTH
 ***************************/
// auth form background that appears on a background that do not change (auth, booking/auth, home)
$auth-form-background-static: rgba(255, 255, 255, 0.9);
$auth-form-text-color-static: $black;
// auth form background that appears on a background that can change over time (landing-page, landing product)
$auth-form-background-dynamic: rgba(255, 255, 255, 0.9);
$auth-form-text-color-dynamic: $black;
$auth-marketing-message-shadow: none;

/***************************
 * = MENU
 ***************************/

$menu-background-color: $color-primary;
$menu-text-color: $black;

/***************************
 * = PLACEHOLDERS
 ***************************/

%booking-title {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	box-sizing: border-box;
	text-align: center;
	min-height: 40px;
	padding: 10px 0;
}

%input-label-touched {
	position: absolute;
	top: 0;
	left: 5px;
	font-size: 1rem;
	color: $active-color;
	background: none;
	padding-right: 10px;
	padding-left: 10px;
}

$mobile-auth-background-color: white;
$auth-layer-mobile-text-color: $black;
$auth-layer-show-headline-on-mobile: block;

$listing-header-filter-max-width: none;
$listing-header-sticky-container-box-shadow: none;
$product-filter-header-box-shadow: none;
$products-filter-selected-border: none;
$products-master-filter-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
$aside-product-filters-menu-filters-title-height: 40px;

$marketing-slide-small-logo: url("/static/icons/icon-marketing-slide-small-logo-af.svg");
$marketing-slide-small-1: url("/static/icons/icon-marketing-slide-small-af-1.svg");
