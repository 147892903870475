@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.merch-carousel-block {
	$this: &;

	&__title {
		margin-bottom: 24px;
	}

	&__introduction {
		margin-top: 5px;

		@include gridle_state(md) {
			font-size: 1.6rem;
		}
	}

	&__merch-title {
		margin-bottom: 20px;
	}

	.current-merch-collection__title {
		display: none;
	}

	&__merchandisings {
		@include gridle_state(md) {
			&--custom {
				position: relative;

				.current-merch-collection {
					&__list {
						justify-content: flex-start;
					}

					&__item {
						width: auto;
					}

					.current-merch-collection-item {
						&__picture {
							height: 208px;
						}

						&__footer {
							padding: 5px 10px;
						}

						&__picture,
						&__footer {
							width: 220px;
						}

						&__stamp {
							.decreasing-price-stamp {
								&__label {
									font-size: 0.9rem;
								}

								&__separator {
									margin-left: 3px;
									margin-right: 3px;
									font-size: 1.4rem;
								}

								.amount {
									font-size: 0.9rem;
								}

								.amount__value {
									font-size: 1.4rem;
								}
							}
						}
					}
				}
			}
		}
	}
}
