@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sdp-reinsurance {
	display: flex;
	align-items: center;
	flex-direction: column;

	@include gridle_state(sm) {
		flex-direction: row-reverse;
		justify-content: center;
	}

	&__introduction {
		margin-top: 8px;

		@include gridle_state(md) {
			font-size: 1.6rem;
		}
	}

	.separator {
		margin-top: 20px;
		margin-bottom: 30px;
		border-width: 2px;
		width: 60px;
		background-color: $color-secondary;
	}

	&__texte {
		margin-top: 24px;
		font-size: 1.4rem;

		@include gridle_state(sm) {
			font-size: 1.5rem;
		}
	}

	&__cta {
		margin-top: 24px;
		display: inline-flex;
		width: fit-content !important;
		padding-left: 20px !important;
		padding-right: 20px !important;

		svg {
			margin-left: 10px;
			height: 10px;
			width: 10px;
		}
	}

	&__image {
		margin-top: 24px;
		height: 100%;
		width: 100%;

		.progressive-image {
			height: 100%;
			width: 100%;
		}

		img {
			border-radius: $border-radius-big;
		}

		@include gridle_state(sm) {
			width: 350px;
			height: 196px;
			margin-right: 20px;
		}

		@include gridle_state(md) {
			margin-top: 0;
			margin-right: 40px;
		}
	}

	&__container {
		flex: 1 1 0%;
	}

	.paragraphs__item {
		&:not(:last-child) {
			margin-bottom: 8px;
		}
		&:last-child {
			a {
				display: inline-block;
				@include gridle_state(md) {
					margin-top: 16px;
				}
			}
		}
	}
}
